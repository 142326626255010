#status-box-container {
    .status-box {
        position: relative;

        >div {
            transition: 0.3s;
        }

        svg {
            >g>circle {
                stroke: none;
                fill: none;
            }
        }
    }

    .fan-inactive {
        opacity: .5;
    }

    #pm_div,
    #voc_div,
    #rh_div {
        svg g g {
            text {
                font-size: 0px;
            }

            path {
                fill: #607d8b;
                fill-opacity: 1;
                stroke: #607d8b;
            }

            circle {
                fill: #cfd8dc;
                stroke: #cfd8dc;
            }
        }

        &>table {
            margin: 0 auto !important;
        }
    }

    #temp_div {
        svg {
            display: block;
            margin: 0 auto;
        }
    }

    .status-label-sm {
        font-size: .75rem !important;
        color: #888 !important;
    }

}

#device-current-status {
    transition: 0.1s;
    width: 340px;

    #collapse-icon-btn {
        bottom: 92px;
        border-radius: 50%;
        right: -14px;
        position: absolute;
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding: 0;
        transition: .2s;
    }

    &.collapsed {
        width: 120px;

        #pm_div,
        #voc_div,
        #rh_div,
        #temp_div {
            display: none;
        }

        .status-box {
            >div {
                box-shadow: none;
            }
        }

        .status-fair,
        .status-poor,
        .status-good {
            text-align: left;
        }
    }
}