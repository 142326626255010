.account-form-label {
    width: 160px;
    span {
        font-size: 0.9rem;
        font-weight: 700;
    }
}
.account-form-value {
    width: calc(100% - 150px);
    span {
        font-size: 0.9rem;
        font-weight: 500;
    }
    b {
        display: inline-block;
        width: 120px;
        margin-left: 1rem;
    }
}
.account-list {
    li {
        padding-left: 0;
    }
}