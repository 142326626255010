#report-content {
    p,
    small,
    li {
        font-size: 12px !important;
    }

    h2 {
        font-size: 16px;
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 12px;
    }
}

.for-print {
    padding-bottom: 1rem;
}