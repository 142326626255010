
body {
    margin: 0;
  }
  .navbar {
    //padding: 20px 0;
    .row {
      width: 100%;
      align-items: center;
      margin: 0;
    }
    &-icon {
      display: flex;
      justify-content: flex-end;
      height: 40px;
      align-items: center;
      padding-right: 10px;
      @media screen and (min-width: 992px) {
        display: none;
      }
      span {
        width: 36px;
        height: 3px;
        background-color: #fff;//#000;
        display: block;
        position: relative;
        transition: 0.3s;
        &:after {
          content: "";
          width: 36px;
          height: 3px;
          position: absolute;
          top: 9px;
          background-color: #fff;//#000;
          transition: 0.3s;
          z-index: 1214;
        }
        &:before {
          content: "";
          width: 36px;
          height: 3px;
          position: absolute;
          bottom: 9px;
          background-color: #fff;//#000;
          transition: 0.3s;
        }
      }
      &.active {
        span {
          background-color: transparent;
          &:before {
            transform: rotate(45deg);
            bottom: 0;
            background-color: #fff;
            z-index: 1214;
          }
          &:after {
            transform: rotate(-45deg);
            background-color: #fff;
            top: 0;
            position: absolute;
            z-index: 1213;
          }
        }
      }
    }
    &-menu {
      nav {
        @media screen and (max-width: 991px) {
          visibility: hidden;
          position: fixed;
        }
        ul {
          margin: 0;
          padding: 0;
          display: flex;
          list-style: none;
          justify-content: flex-end;
          li {
            margin: 0;
            @media screen and (max-width: 991px) {
              transform: translateX(-10px);
              opacity: 0;
            }
            a {
              display: block;
             // padding: 10px 20px;
              color: rgb(248, 245, 245);
              transition: 0.3s;
            }
            &:hover {
              a {
                text-decoration: none;
                transform: translateY(-3px);
              }
            }
          }
        }
        &.active {
          @media screen and (max-width: 991px) {
            visibility: visible;
            position: fixed; //absolute;//
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #606F78;//#292929;
            opacity: 1;
            z-index: 1211;
          }
          ul {
            @media screen and (max-width: 991px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
            li {
              @media screen and (max-width: 991px) {
                transform: translateX(0);
                opacity: 1;
                transition: 0.9s;
                @for $i from 1 through 9 {
                  &:nth-child( #{$i} ) {
                    transition-delay: #{$i * 0.15}s;
                  }
                }
                a {
                  color: #fff;
                  font-size: 16px;//22px;
                 // position: absolute;//fixed;
                  padding: 10px 10px;
                  z-index: 1212;
                 // touch-action: cross-slide-x;
                }
              }
            }
          }
        }
        &.hidden{
            
                @media screen and (max-width: 991px) {
                  transition: 0.9s;
                  visibility: hidden;
                  position: fixed;
                }
        }
      }
    }
   
   
    .jss9 {
        color: #535F66;
        border: 1px solid #535F66;
        margin-left: 0.5rem;
        margin-top: 0.9rem;
        border-radius: 4px;
        background-color: #FFFFFF;
        height: max-content;
    }
   
    .makeStyles-whiteButton-13 {
        color: #535F66;
        border: 1px solid #535F66;
        //margin-left: 0.5rem;
        margin-right: 0.9rem;
        margin-top: 0.9rem;
        border-radius: 4px;
        background-color: #FFFFFF;
        height: max-content;
    }

    a.makeStyles-menuButton-47 {
        color: white;
        border: 0px solid;
        padding: 1.5em 1em;
        position: relative;
        font-size: 0.875rem;
        text-align: right;//center;
        font-family: Rubik,Helvetica Neue,Arial,sans-serif;
        font-weight: 500;
        line-height: 1.75;
        margin-right: 1em;
        text-transform: uppercase;
        //padding: 10px;
       // z-index: 2;
    }
  }
  