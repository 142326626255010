#drag-drop-container {
    border-radius: .5rem;
    display: inline-block;
    height: 200px;
    position: relative;
    width: 400px;
    .drag-inner-overlay {
        background-color: rgba(255, 255, 255, .5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        & > div {
            position: absolute;
            right: 0;
            left: 0;
            text-align: center;
        }
    }
    #logo-preview {
        max-width: 396px;
        max-height: 194px;
    }
    #logo_delete {
        background-color: white;
        opacity: .7;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    #file_upload {
        padding: 0;
        text-decoration: underline;
        text-transform: none;
    }
}