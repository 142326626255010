/* move the gantt chart up to connect with top chart */
#container-1 {
  transform: translate(-238px, -12px);
  z-index: 9;
}

#container-1-report {
  transform: translate(0, -8px);
}

#container-0 {
  position: relative;
  z-index: 0;
}

/* for responsive chart */
.highcharts-container {
  width: 100%;
}

.highcharts-container svg {
  width: 100%;
  display: flex;
}