//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import '~react-flow-renderer/dist/style.css';
@import '~react-flow-renderer/dist/theme-default.css';

// With the position fixed and width and height to 100% of the viewport we are able to fill the body and root element
// to fit its content size and able to do some responsive screens such as the login-redirect-redirect.tsx.
body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  //display: inline-block;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
// TODO-lsantos:
// The section commented bellow it's how it was originally coded. I'll keep here for now just in case if the changes
// above screw anything related to the layout and its orientation/width/height/scrollbar or anything alike.
//body, html, #root {
//  height: 100%;
//}
//body {
//  margin: 0;
//  font-family: 'Rubik', sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
@media(max-width: 1040px) {
  body {
    overflow: scroll;
    width: 1040px;
  }
}
.highcharts-root {
  font-family: 'Rubik', sans-serif;
}
a {
  text-decoration: none;
}
.page {
  padding: 1.5rem;
  h2 {
    margin: 0;
  }
}

ul, li {
  padding: 0;
}

/* -- calendly has a style bug for the popup height, this fixes it -- */
.calendly-overlay .calendly-popup {
  max-height: 800px !important;
}
