#onboarding-container {
  background: linear-gradient(233.79deg, #29BDBC 30.31%, #FFE61B 103.33%);
    width: 100%;
    height: calc(100% - 90px);
    overflow: auto;
    &.full-height {
        height: 100%;
    }
    h1, h5 {
      color: white;
    }
    .onboarding-card {
      transition: 0.3s;
      opacity: 1;
      border-radius: 20px;
    }
    .onboarding-fade {
      transition: 0.3s;
      opacity: 0.3;
      border-radius: 20px;
      &:hover,
      &:active,
      &:focus {
          opacity: 1;
      }
    }
}
