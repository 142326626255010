.sales-file-list-item {
    border-radius: 10px;
    transition: .3s;
    margin-bottom: 1rem;

    &:hover{
        background-color: #E0E0E0;
    }
    img {
        width: 40px;
    }
}
