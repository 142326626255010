.threshold_good {
    border-top: 2px dashed #58eac7;
}

.threshold_fair {
    border-top: 2px dashed #e5ba00;
}

.threshold_poor {
    border-top: 2px dashed #f36262;
}

// .MuiTable-root tr {
//     &:hover {
//         background-color: #EDEDED;
//     }
// }

.highcharts-reset-zoom text {
    text-transform: uppercase;
    transform: translateY(1px);

    &:not(:hover) {
        fill: #00C4C1 !important; // hard coded for now
    }
}

.highcharts-button:hover {
    text {
        fill: white !important;
    }
}

.text-center {
    text-align: center;
}

.margin-x-0 {
    margin: 0 auto;
}

.display-block {
    display: block;
}

.cursor-pointer {
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}

/*
* set plotline label border radius
*/
.highcharts-plot-line-label {
    border-radius: 10px;
    text-align: center;
    width: 44px;
}

/*
* add 'dot' to yAxis label for line chart
*/
.line-y-axis {
    .highcharts-axis-title {
        position: relative;

        &:before {
            content: '●';
            color: inherit;
            position: absolute;
            left: -16px;
        }
    }
}

// bold style on tooltip
.MuiTooltip-popper b {
    font-weight: 900;
}

.StripeElement {
    flex-grow: 1
}

#report-to-send {
    display: none
}

#report-page {
    break-after: page
}

.MuiTableCell-root .MuiTablePagination-toolbar,
.MuiTableCell-root .MuiTableCell-footer .MuiTablePagination-root {
    border-bottom: none !important;
}

.MuiTableCell-root {
    border-bottom: none !important;
}

@media print {
    .highcharts-container {
        width: 100% !important;
    }

    body {
        visibility: hidden;
        position: relative;
    }

    #report-to-send {
        display: block;
        visibility: visible;
        position: absolute;
        z-index: 99;
        left: 0;
        top: 0;

        .MuiPaper-elevation3 {
            box-shadow: none;
        }
    }
}