.home-page {
  background: linear-gradient(233.79deg, #29BDBC 30.31%, #FFE61B 103.33%);
  background-size: cover;
  color: white;
  width: 100%;
  height: 100%;

  .product-offering {
    border: 1px solid #FFFFFF;
    border-radius: 20px;
  }
  .product-value-heading {
    position: relative;
    min-height: 214px;
    display: flex;
    align-items: flex-end;
  }
  .product-value-image {
    position: absolute;
    right: 0;
    bottom: -12px;
    pointer-events: none;
  }
}
