.company-form-label {
    width: 210px;
    max-width: 210px;
    span {
        font-size: 0.9rem;
        font-weight: 700;
    }
}
.company-form-value {
    span {
        font-size: 0.9rem;
    }
}
.company-list {
    li {
        padding-left: 0;
    }
}
#company-logo_upload {
    cursor: pointer;
    &:hover {
        background-color: #EEE;
    }
}

#company-logo-display {
    position: relative;
    img {
        max-width: 160px;
        max-height: 120px;
    }
}
#logo_edit {
    background-color: white;
    min-width: 40px;
    min-height: 40px;
    opacity: .95;
    position: absolute;
    bottom: 10px;
    right: 6px;
}