#customer-history {
    .radio-data-type {
        margin-right: 4rem;
    }
    #chart_submit {
        padding-right: 0;
        padding-left: 0;
        min-width: 40px !important;
        height: 40px;
    }
}